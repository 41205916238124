export const companyListToDropdownFormat = (
    list: any[],
    labelParam: string,
    valueParam: string,
) => {
    const options: any[] = [];
    if (list && list.length > 0) {
        list.map((item: any) => {
            const optionObj = { id: "", label: "", value: "" };
            /* eslint-disable no-console */
            console.log("Item values:: ", item);
            optionObj.id = item.value;
            optionObj.label = labelParam ? item[labelParam] + " " + "(" + optionObj.id + ")" : item;
            optionObj.value = valueParam ? item[valueParam] : item;
            options.push(optionObj);
        });
    }
    return options;
};