import { api } from "../../../common-ui-modules";
import { API_URL } from "../constants";

export const UpdateUserInfo = (infoData: any, broadcomEmail: string) => {
    let data = {};
    if (infoData.isEnterprise) {
        data = {
            email: infoData.email,
            firstName: infoData.firstName,
            lastName: infoData.lastName,
            middleName: infoData.middleName,
            jobTitle: infoData.jobTitle,
            phone: infoData.phone,
            addressLine1: infoData.addressLine1,
            addressLine2: infoData.addressLine2,
            city: infoData.city,
            stateName: infoData.stateName,
            postalCode: infoData.postalCode,
            countryId: infoData.country,
            customerId: infoData.customerId,
            companyId: infoData.companyId,
            otherCompanyName: infoData.companyId === 5000000 ? infoData.otherCompanyName : null,
            supportId: infoData.supportId,
            sourceId: infoData.sourceId,
            registrationId: infoData.registrationId,
            broadcomContactEmail: broadcomEmail,
            province: infoData.province,
            mobile: infoData.mobile,
            faxNumber: infoData.fax,
            defaultCurrency: infoData.defaultCurrency,
            // timezone:infoData.timezone,
            uuid: null,
            siteId: null,
            source: "PORTAL",
            //activeFlag: infoData.isActive ? "Y" : "N", //To Enable when required
        };
    } else {
        data = {
            email: infoData.email,
            firstName: infoData.firstName,
            lastName: infoData.lastName,
            customerId: infoData.customerId,
            companyId: infoData.company.companyId || infoData.companyId || null,
            sourceId: [3],
            registrationId: infoData.registrationId,
            source: "PORTAL",
        };
    }

    //TODO: Make it a variable
    const headers = {
        "Content-Type": "application/json",
    };

    return api({
        url: API_URL.UPDATE_USER_INFO,
        method: "POST",
        data: JSON.stringify(data),
        headers,
        skipErrorHandling: true,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
};
