import RegistrationPage from "../../modules/Registration/index";
import ProfileBuilderPage from "../../modules/Registration/index";
import MyProfile from "../../modules/Registration/index";
import LoginCallbackPage from "../../modules/Registration/index";
import CompanyManagementHome from "../../modules/CompanyManagement/CompanyManagementHome";
import UserManagementPage from "../../modules/UserManagement";
import ForgotPasswordHome from "../../modules/Registration/ui/pages/forgot-password/ForgotPasswordHome";
import CustomerQueryToolPage from "../../modules/CustomerQueryTool";
import { NoAccess } from "../../common-ui-modules/components/NoAccess/NoAccess";

export enum ROUTE_PATHS {
    HOME = "/",
    REGISTRATION = "/web/registration",
    FORGOTPASSWORD = "/web/forgot-password",
    UPGRADE = "/upgrade",
    LOGIN = "/login",
    LOGIN_CALLBACK = "/login/callback",
    PROFILE = "/me",
    LOGOUT = "/logout",
    COMPANY_MANAGEMENT = "/company-management",
    USER_MGMT = "/user-management",
    CUSTOMER_QUERY_TOOL = "/customer-query-tool",
    NO_ACCESS = "/no-access",
    BASIC_USER_MGMT = "/basic-user-management",
}

export const routesConfig = [
    { path: ROUTE_PATHS.REGISTRATION, component: RegistrationPage },
    { path: ROUTE_PATHS.HOME, component: RegistrationPage },
    { path: ROUTE_PATHS.LOGIN_CALLBACK, component: LoginCallbackPage },
    { path: ROUTE_PATHS.FORGOTPASSWORD, component: ForgotPasswordHome },
    { path: ROUTE_PATHS.UPGRADE, component: ProfileBuilderPage },
    { path: ROUTE_PATHS.PROFILE, component: MyProfile },
    { path: ROUTE_PATHS.COMPANY_MANAGEMENT, component: CompanyManagementHome },
    { path: ROUTE_PATHS.USER_MGMT, component: UserManagementPage },
    { path: ROUTE_PATHS.CUSTOMER_QUERY_TOOL, component: CustomerQueryToolPage },
    { path: ROUTE_PATHS.NO_ACCESS, component: NoAccess },
    { path: ROUTE_PATHS.BASIC_USER_MGMT, component: UserManagementPage },
];
